import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Suspense, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import { uiColors, primaryColor } from './common/styles';
import { AppProvider, AuthProvider } from './providers';

import Loader, { LoaderWrapper } from './components/common/Loader';
import './index.css';
import { lazyWithPreload } from './utilities/lazy-with-preload';
import { usePosthog } from './providers/PosthogProviderLite';

const NavigationProvider = lazyWithPreload(() =>
    import(/* webpackChunkName: 'NavigationProvider'*/ './providers/NavigationProvider').then((module) => ({
        default: module.NavigationProvider,
    }))
);
const FramkyProvider = lazyWithPreload(() =>
    import(/* webpackChunkName: 'FramkyProvider' */ './providers/FramkyProvider').then((module) => ({
        default: module.FramkyProvider,
    }))
);
const ModalProvider = lazyWithPreload(() =>
    import(/* webpackChunkName: 'ModalProvider' */ './providers/ModalProvider').then((module) => ({
        default: module.ModalProvider,
    }))
);
const OrderProvider = lazyWithPreload(() =>
    import(/* webpackChunkName: 'OrderProvider' */ './providers/OrderProvider').then((module) => ({
        default: module.OrderProvider,
    }))
);
const Studio = lazyWithPreload(() => import(/* webpackChunkName: 'Studio', webpackPrefetch: true */ './views/Studio'));
const NotFound = lazyWithPreload(() => import(/* webpackChunkName: 'NotFound' */ './views/NotFound'));
const CheckoutPayment = lazyWithPreload(
    () => import(/* webpackChunkName: 'CheckoutPayment' */ './views/CheckoutPayment')
);
const GoogleAuth = lazyWithPreload(() => import(/* webpackChunkName: 'GoogleAuth' */ './views/GoogleAuth'));
const PasswordResetForm = lazyWithPreload(
    () =>
        import(
            /* webpackChunkName: 'PasswordResetForm' */ './components/studio/drawer-content/account/PasswordResetForm'
        )
);
const EmailAuth = lazyWithPreload(() => import(/* webpackChunkName: 'EmailAuth' */ './views/EmailAuth'));

const BatchPhotoEditor = lazyWithPreload(() => import(/* webpackChunkName: 'BatchPhotoEditor' */ './views/batchPhotoEditor/BatchPhotoEditor'));

function App() {
    const [isFeatureFlagAvailable, setIsFeatureFlagAvailable] = useState(false);
    const intl = useIntl();
    const posthog = usePosthog();

    useEffect(() => {
        document.title = intl.formatMessage({ id: 'studio.document.title' });
        FramkyProvider.preload();
        Studio.preload();
        NavigationProvider.preload();
        
        const preloadComponents = () => {
            ModalProvider.preload();
            // CheckoutPayment.preload();
            // PasswordResetForm.preload();
            // NotFound.preload();
            // GoogleAuth.preload();
            // EmailAuth.preload();
        };

        if ('requestIdleCallback' in window) {
            requestIdleCallback(preloadComponents);
        } else {
            preloadComponents();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const theme = createTheme({
        palette: {
          primary: primaryColor,
          text: {
            primary: uiColors.SECONDARY_TEXT,
            secondary: uiColors.SECONDARY_TEXT,
          }
        },
        typography: {
          allVariants: {
            color: uiColors.PRIMARY_TEXT,
          },
        },
      });

    posthog.onFeatureFlags((flags) => {
        if (!isFeatureFlagAvailable) {
            setIsFeatureFlagAvailable(true);
        }
    });

    return (
        <BrowserRouter>
            {/* Add Toaster for react-hot-toast */}
            <Toaster />
            <ThemeProvider theme={theme}>
                <AppProvider>
                    <AuthProvider isFeatureFlagAvailable={isFeatureFlagAvailable}>
                        <Suspense
                            fallback={
                                <LoaderWrapper $overlap={true}>
                                    <Loader />
                                </LoaderWrapper>
                            }
                        >
                            <FramkyProvider>
                                <NavigationProvider>
                                    <OrderProvider>
                                        <ModalProvider>
                                            {/* <ProductTour /> */}
                                            <Routes>
                                                <Route path="/auth/email/confirmation/:uid?/:token/" element={<EmailAuth />} />
                                                <Route path="/auth/google/" element={<GoogleAuth />} />
                                                <Route
                                                    path="/auth/password/reset/:uid/:token"
                                                    element={<PasswordResetForm confirmMode={true} />}
                                                />
                                                <Route path="/quickstart/" element={<Studio />} />
                                                <Route path="/:framiId?/:compId?/:drawerId?" element={<Studio />} />
                                                <Route path="/checkout/payment/" element={<CheckoutPayment />} />
                                                <Route path="/batch_photo_editor/:batchId?" element={<BatchPhotoEditor />} />
                                                <Route path="*" element={<NotFound />} />
                                            </Routes>
                                        </ModalProvider>
                                    </OrderProvider>
                                </NavigationProvider>
                            </FramkyProvider>
                        </Suspense>
                    </AuthProvider>
                </AppProvider>
            </ThemeProvider>
        </BrowserRouter>
    );
}

export default App;
