import Storage from "./Storage";

class TokenKeeper {
    token = null;

    constructor() {
        this.token = Storage.getItem('token');
    }

    getToken() {
        return this.token;
    }

    setToken(token) {
        Storage.setItem('token', token);
        this.token = token;
    }

    removeToken() {
        Storage.removeItem('token');
        this.token = null;
    }
}

export default new TokenKeeper();
