export const coolBoxShadow = 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px';
export const smallElevationShadow = `rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px`;
export const revertedSmallElevationShadow = `rgba(50, 50, 93, 0.08) 0px -2px 5px -1px, rgba(50, 50, 93, 0.08) 0px 2px 5px -1px`;
export const elevationShadow = `0px 4px 16px 0px rgba(0, 0, 0, 0.24)`;
export const contentPaddingSides = '16px';
export const borderRadius = '12px';
export const uiColors = {
    PRIMARY: '#FF4673',    
    SECONDARY: '#658BFF',
    GRAY: '#656565',    
    ERROR: '#FF0000',
    GRADIENT: '#F7F2FA',

    //Figma colors
    PRIMARY_TEXT: '#13181F',
    SECONDARY_TEXT: '#637288',
    DISABLED_TEXT: '#C3C3C3',
    LINK_TEXT: '#222B39',
    ACTIVE_BORDER: '#637288',
    SELECTED_BORDER: '#2D3746',
    DISABLED_BORDER: '#E1E2E1',
    DIVIDER: '#E1E2E1',
    WHITE: '#FFFFFF',
    BACKGROUND: '#F5F5F6',
    BLACK: '#111111',
    PURPLE_900: '#410078',
    PURPLE_800: '#56188B',
    PURPLE_500: '#732DA8',
    PURPLE_300: '#A55CDA',
    PURPLE_200: '#AD81CC',
    PURPLE_50: '#F7F2FA',
    GRAY_1000: '#222B39',
    GRAY_900: '#384353',
    GRAY_800: '#4E5A6D',
    GRAY_400: '#A5B4C9',
    GRAY_300: '#BCC7D7',
    GRAY_200: '#D2D9E4',
    GRAY_100: '#E9ECF2',
    GRAY_50: '#F4F6F8',
    PRIMARY_1000: "#980025",
    PRIMARY_900: '#AD0E35',
    PRIMARY_800: '#C11C44',
    PRIMARY_500: '#FF4673',
    PRIMARY_300: '#FF8BA7',
    PRIMARY_200: '#FFAEC2',
    PRIMARY_100: '#FFAEC2',
    PRIMARY_50: '#FFE2E9',
    PRIMARY_0: '#FFF3F6',
    TRUSTPILOT: '#00B67A',
    GREEN: '#76D35F',
    GREEN_1000: '#002F08',
    GREEN_900: '#005B12',
    GREEN_800: '#007A1A',
    GREEN_400: '#00CC40',
    GREEN_300: '#33D65E',
    GREEN_200: '#66E785',
    GREEN_100: '#99FABD',
    GREEN_50: '#CCFFDB',
    WARNING: '#FFE03C',
    WARNING_50: '#FFFBCA',
    BLUE: '#658BFF',
    BLUE_50: "#F0F3FF",
    YELLOW: "#ffed65",
    PROMO: "#FF2971",
};
// export const mobileModalBoxStyle = {
//     width: '90%',
//     height: '80%',
// };
export const borderStyle = {
    radius: '8px',
    outline: `2px solid ${uiColors.PRIMARY_500}`,
    outlineTransparent: `2px solid ${uiColors.WHITE}`,
    outlineBoxShadow: `0 0 0 1px ${uiColors.GRAY_400}`,
    outlineBoxShadowLight: `0 0 0 2px ${uiColors.PRIMARY_50}`,
    outlineBoxShadowSelected: `0 0 0 2px ${uiColors.PRIMARY_500}`,
}
export const selectedBorderStyle = {
    radius: '8px',
    outline: `2px solid ${uiColors.PRIMARY_500}`,
    outlineBoxShadow: `0 0 0 2px ${uiColors.PRIMARY_500}`,
}

export const primaryColor = {
    50: uiColors.PRIMARY_50,
    100: uiColors.PRIMARY_100,
    200: uiColors.PRIMARY_200,
    500: uiColors.PRIMARY_500,
    800: uiColors.PRIMARY_800,
    // /**
    //  * Preview: ![lime 200](https://mui.com/static/colors-preview/lime-200-24x24.png)
    //  */
    // 200: '#e6ee9c';
    // /**
    //  * Preview: ![lime 300](https://mui.com/static/colors-preview/lime-300-24x24.png)
    //  */
    // 300: '#dce775';
    // /**
    //  * Preview: ![lime 400](https://mui.com/static/colors-preview/lime-400-24x24.png)
    //  */
    // 400: '#d4e157';
    // /**
    //  * Preview: ![lime 500](https://mui.com/static/colors-preview/lime-500-24x24.png)
    //  */
    // 500: '#cddc39';
    // /**
    //  * Preview: ![lime 600](https://mui.com/static/colors-preview/lime-600-24x24.png)
    //  */
    // 600: '#c0ca33';
    // /**
    //  * Preview: ![lime 700](https://mui.com/static/colors-preview/lime-700-24x24.png)
    //  */
    // 700: '#afb42b';
    // /**
    //  * Preview: ![lime 800](https://mui.com/static/colors-preview/lime-800-24x24.png)
    //  */
    // 800: '#9e9d24';
    // /**
    //  * Preview: ![lime 900](https://mui.com/static/colors-preview/lime-900-24x24.png)
    //  */
    // 900: '#827717';
    // /**
    //  * Preview: ![lime A100](https://mui.com/static/colors-preview/lime-A100-24x24.png)
    //  */
    // A100: '#f4ff81';
    // /**
    //  * Preview: ![lime A200](https://mui.com/static/colors-preview/lime-A200-24x24.png)
    //  */
    // A200: '#eeff41';
    // /**
    //  * Preview: ![lime A400](https://mui.com/static/colors-preview/lime-A400-24x24.png)
    //  */
    // A400: '#c6ff00';
    // /**
    //  * Preview: ![lime A700](https://mui.com/static/colors-preview/lime-A700-24x24.png)
    //  */
    // A700: '#aeea00';
  };