// Konwersja hex -> Lab
export function hexToLab(hex) {
    if (hex.startsWith('#')) {
        hex = hex.slice(1);
    }
    if (hex.length !== 6) {
        throw new Error("Invalid HEX color");
    }
    const r = parseInt(hex.slice(0, 2), 16) / 255;
    const g = parseInt(hex.slice(2, 4), 16) / 255;
    const b = parseInt(hex.slice(4, 6), 16) / 255;

    // sRGB -> liniowość
    const toLinear = (c) => (c <= 0.04045 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4));
    const rLin = toLinear(r);
    const gLin = toLinear(g);
    const bLin = toLinear(b);

    // Konwersja do XYZ (D65)
    const x = rLin * 0.4124 + gLin * 0.3576 + bLin * 0.1805;
    const y = rLin * 0.2126 + gLin * 0.7152 + bLin * 0.0722;
    const z = rLin * 0.0193 + gLin * 0.1192 + bLin * 0.9505;

    // Biały punkt D65
    const Xn = 0.95047, Yn = 1.0, Zn = 1.08883;
    const f = (t) => (t > 0.008856 ? Math.cbrt(t) : (7.787 * t + 16 / 116));
    const fx = f(x / Xn);
    const fy = f(y / Yn);
    const fz = f(z / Zn);

    const L = y / Yn > 0.008856 ? (116 * fy - 16) : (903.3 * y / Yn);
    const a = 500 * (fx - fy);
    const bVal = 200 * (fy - fz);
    return [L, a, bVal];
}

// Konwersja Lab -> hex
export function labToHex(lab) {
    const [L, a, b] = lab;
    // Odwrotność funkcji f
    const fy = (L + 16) / 116;
    const fx = a / 500 + fy;
    const fz = fy - b / 200;
    const fInv = (t) => {
        const t3 = t * t * t;
        return (t3 > 0.008856 ? t3 : (t - 16 / 116) / 7.787);
    };

    const Xn = 0.95047, Yn = 1.0, Zn = 1.08883;
    const x = fInv(fx) * Xn;
    const y = fInv(fy) * Yn;
    const z = fInv(fz) * Zn;

    // Konwersja XYZ -> liniowe RGB
    let rLin = x * 3.2406 + y * -1.5372 + z * -0.4986;
    let gLin = x * -0.9689 + y * 1.8758 + z * 0.0415;
    let bLin = x * 0.0557 + y * -0.2040 + z * 1.0570;

    // Liniowe RGB -> sRGB
    const toSRGB = (c) =>
        c <= 0.0031308 ? 12.92 * c : 1.055 * Math.pow(c, 1 / 2.4) - 0.055;
    let r = toSRGB(rLin);
    let g = toSRGB(gLin);
    let bConv = toSRGB(bLin);

    // Klampowanie do zakresu 0-1
    r = Math.min(Math.max(r, 0), 1);
    g = Math.min(Math.max(g, 0), 1);
    bConv = Math.min(Math.max(bConv, 0), 1);

    const toHex = (c) => {
        const hex = Math.round(c * 255)
            .toString(16)
            .padStart(2, '0');
        return hex;
    };

    return '#' + toHex(r) + toHex(g) + toHex(bConv);
}