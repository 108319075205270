import debounce from 'lodash/debounce';
import React, { useEffect, useMemo, useState } from 'react';
import env from 'react-dotenv';
import useGeoLocation from 'react-ipgeolocation';
import { AppContext } from '../context';

export function AppProvider({ children }) {
    const [isMobile, setIsMobile] = useState(false);
    const [smallHeightDevice, setSmallHeightDevice] = useState(false);
    const [isHighDensity, setIsHighDensity] = useState(false);
    const [isSafari, setIsSafari] = useState(false);
    const [isTouchDevice, setIsTouchDevice] = useState(false);
    const location = useGeoLocation();

    useEffect(() => {
        const mobileMaxWidth = parseInt(env.MOBILE_MAX_WIDTH) || 1150;
        const mobileMinHeight = 460;

        const checkIsMobile = () => {
            const isPortrait = window.matchMedia && window.matchMedia('(orientation: portrait)').matches;
            setSmallHeightDevice(window.innerHeight <= mobileMinHeight);
            if (window.innerWidth <= mobileMaxWidth && isPortrait) {
                return setIsMobile(true);
            }
            setIsMobile(false);
        };
        const debouncedCheckIsMobile = debounce(checkIsMobile, 1000);

        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        setIsSafari(isSafari);

        checkIsMobile();
        window.addEventListener('resize', debouncedCheckIsMobile);

        return () => window.removeEventListener('resize', debouncedCheckIsMobile);
    }, []);

    useEffect(() => {
        function isHighDensity() {
            const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
            
            if (isFirefox) return false;
            
            return (
                (window.matchMedia &&
                    (window.matchMedia(
                        'only screen and (min-resolution: 124dpi), only screen and (min-resolution: 1.3dppx), only screen and (min-resolution: 48.8dpcm)'
                    ).matches ||
                        window.matchMedia(
                            'only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (min-device-pixel-ratio: 1.3)'
                        ).matches)) ||
                (window.devicePixelRatio && window.devicePixelRatio > 1.3)
            );
        }
        setIsHighDensity(isHighDensity());
    }, []);

    useEffect(() => {
        setIsTouchDevice('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0);
        // console.log("Touch", matchMedia("(pointer: coarse)").matches);
    }, []);

    const contextState = useMemo(
        () => ({
            isMobile,
            smallHeightDevice,
            isHandheldDevice: isMobile || smallHeightDevice,
            isHighDensity,
            isSafari,
            isTouchDevice,
            location,
        }),
        [isMobile, smallHeightDevice, isHighDensity, isSafari, location]
    );

    return <AppContext.Provider value={contextState}>{children}</AppContext.Provider>;
}
