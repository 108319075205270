import { generateRandomId } from './helpers';

const FEED_FRAMES_COUNT_GROUPS = [
    { min: 1, max: 7, group: '1-7' },
    { min: 8, max: 11, group: '8-11' },
    { min: 12, max: 14, group: '12-14' },
    { min: 15, max: 25, group: '15+' },
];

function findFrameSizeById(frameSizes, frameId) {
    return frameSizes.get(frameId);
}

export function convertTemplateToFrames(template, frameSizes) {
    if (Array.isArray(template.frames)) {
        const framesWithId = template.frames.map(frame => ({
            ...frame,
            id: generateRandomId()
        }));
        return framesWithId;
    }
    const frames = [];
    Object.keys(template.frames).forEach((key) => {
        const framePositions = template.frames[key];
        const frameSize = findFrameSizeById(frameSizes, key);
        if (frameSize) {
            const { width, height } = frameSize;
            framePositions.forEach((position) => {
                const id = generateRandomId()
                const [x, y] = position;
                const frame = { id, x, y, width, height, frame_size: frameSize.id };
                frames.push(frame);
            });
        }
    });
    return frames;
}

export const assignFrameCountGroup = (framesCount) => {
    for (const interval of FEED_FRAMES_COUNT_GROUPS) {
        if (interval.min <= framesCount && framesCount <= interval.max) {
            return interval.group;
        }
    }
    return null;
};

export const getLUTFiltercategory = (lutFilter) => {
    let lutFilterCategory = lutFilter?.category;
    if (lutFilterCategory === 'SEPIA' || !lutFilterCategory) {
        lutFilterCategory = 'COLOR';
    }
    return lutFilterCategory;
}

export const hasMargin = (passepartoutPreset) => {
    return passepartoutPreset === "None" ? "F" : "T"
}
