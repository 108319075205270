import React from 'react';
import styled, { css } from 'styled-components/macro';
import { MdOutlineRadioButtonChecked, MdOutlineRadioButtonUnchecked } from 'react-icons/md';
import { uiColors } from '../../common/styles';

export const ButtonGroup = styled.div`
    display: flex;
    flex-direction: ${(props) => (props.$row ? 'row' : 'column')};
    flex-wrap: ${props => props.$wrap ? 'wrap' : 'nowrap'};
    justify-content: flex-start;
    gap: ${(props) => (props.$row ? (props.$isSmall ? '4px' : '8px') : '')};
`;

export const GridButtonGroup = styled.div`
    display: grid;
    grid-template-columns: ${(props) => `repeat(auto-fill, minmax(${props.$size ?? 50}px, 1fr))`};
    gap: ${(props) => (props.$size ? '8px' : '')};
    width: 100%;
    margin: 8px 0;
    padding: 2px;
`;

export const FlexSpacer = styled.div`
    flex-grow: 1;
`;

export const OptionWrapper = styled.div`
    border-radius: 8px;
    border-left: 1px solid ${(props) => (props.disabled ? uiColors.DISABLED_BORDER : uiColors.ACTIVE_BORDER)};
    border-right: 1px solid ${(props) => (props.disabled ? uiColors.DISABLED_BORDER : uiColors.ACTIVE_BORDER)};
    border-bottom: 1px solid ${(props) => (props.disabled ? uiColors.DISABLED_BORDER : uiColors.ACTIVE_BORDER)};

    ${(props) =>
        !props.$row
            ? css`
                  &:first-child {
                      border-top: 1px solid ${(props) => (props.disabled ? uiColors.DISABLED_BORDER : uiColors.ACTIVE_BORDER)};
                      border-radius: 8px 8px 0 0;
                  }
                  &:last-child {
                      border-bottom: 1px solid ${(props) => (props.disabled ? uiColors.DISABLED_BORDER : uiColors.ACTIVE_BORDER)};
                      border-radius: 0 0 8px 8px;
                  }
              `
            : css`
                  border-top: 1px solid ${(props) => (props.disabled ? uiColors.DISABLED_BORDER : uiColors.ACTIVE_BORDER)};
                  outline: ${(props) => (props.selected ? `2px solid ${uiColors.PRIMARY_500}` : 'unset')};
                  border-color: ${(props) => (props.selected ? 'transparent' : 'unset')};
                  margin: 8px 4px 0 4px;
              `};
`;

export const OptionButton = styled.button`
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: 700;
    word-wrap: break-word;
    margin: 0;

    &:disabled {
        opacity: 70%;
        cursor: unset;
    }
`;

export const OptionHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
`;

export const OptionName = styled.span`
    font-size: 16px;
    font-weight: 400;
    color: ${(props) => (props.disabled ? uiColors.GRAY_400 : uiColors.PRIMARY_TEXT)};
    flex-grow: 1;
    text-align: left;
    margin: 6px 0;

    @media (max-width: 360px) {
        max-width: 240px;
    }
    @media (min-width: 1150px) {
        max-width: 260px;
    }
`;

export const OptionPrice = styled.span`
    font-size: 16px;
    font-weight: 700;
    color: ${(props) => (props.disabled ? uiColors.GRAY_400 : uiColors.PRIMARY_TEXT)};
    margin-right: 10px;
    text-align: right;
    min-width: 60px;
`;

export const OptionDescription = styled.p`
    font-size: 12px;
    font-weight: 400;
    color: ${(props) => (props.disabled ? uiColors.GRAY_400 : uiColors.PRIMARY_TEXT)};
    text-align: left;
    margin-left: 40px;
    margin-right: 20px;
    margin-bottom: 10px;
`;

export const OptionLabel = styled.div`
    align-items: center;
`;

export const OptionBody = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
`;
