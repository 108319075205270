import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components/macro';

import { borderStyle, uiColors } from '../../common/styles';
import { ButtonGroup } from './OptionButton';
import { useAppContext, useCompositionTemplates } from '../../context';

const SizeButton = styled.button((props) => ({
    fontWeight: 700,
    fontSize: '17px',
    minHeight: '64px',
    minWidth: '92px',
    letterSpacing: '0.46px',
    borderRadius: borderStyle.radius,
    outline: props.selected ? borderStyle.outline : `1px solid ${uiColors.ACTIVE_BORDER}`,
    backgroundColor: props.selected ? uiColors.PRIMARY_0 : '',
    color: uiColors.PRIMARY_TEXT,
    padding: '8px 0',
    flex: 1,
}));

const SizeButtonUnit = styled.div`
    font-weight: 400;
    font-size: 12px;
`;

const SizeButtonTitle = styled.div`
    align-self: stretch;
    font-weight: 400;
    font-size: 14px;
    color: ${uiColors.SECONDARY_TEXT};
    padding-top: 8px;
`;

export const SIZES = {
    small: { w: 1200, h: 800 },
    medium: { w: 1500, h: 1000 },
    large: { w: 2500, h: 1500 },
};

export const STAIRS_SIZES = {
    small: { w: 2000, h: 2000 },
    medium: { w: 3000, h: 3000 },
    large: { w: 4000, h: 4000 },
};

const CompositionSize = ({ size, onChangeSize }) => {
    const { smallHeightDevice } = useAppContext();
    const { isCategoryStairs } = useCompositionTemplates();
    const intl = useIntl();

    const sizes = isCategoryStairs ? STAIRS_SIZES : SIZES;

    const handleClick = (size) => {
        onChangeSize(sizes[size].w, sizes[size].h);
    };

    return (
        <ButtonGroup $row $isSmall={smallHeightDevice}>
            {Object.keys(sizes).map((s) => (
                <SizeButton
                    key={s}
                    data-cy={`size-select-${s}`}
                    disabled={size.width === sizes[s].w && size.height === sizes[s].h}
                    selected={size.width === sizes[s].w && size.height === sizes[s].h}
                    onClick={() => handleClick(s)}
                >
                    {Math.floor(sizes[s].w / 10)}{!isCategoryStairs && (
                        `\u00A0x\u00A0${Math.floor(sizes[s].h / 10)}`
                    )}
                    <SizeButtonUnit>(cm)</SizeButtonUnit>
                    <SizeButtonTitle selected={size.width === sizes[s].w && size.height === sizes[s].h}>
                        {intl.formatMessage({ id: `studio.composition.size.${s}` })}
                    </SizeButtonTitle>
                </SizeButton>
            ))}
        </ButtonGroup>
    );
};

export default CompositionSize;
