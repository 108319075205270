export const logError = async (error) => {
    console.log(error);

    try {
        const Sentry = await import('@sentry/react');
        Sentry.captureException(error);
    } catch (importError) {
        console.error('Sentry import failed:', importError);
    }
};
