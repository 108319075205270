import React, { createContext, useContext, useMemo } from 'react';
import PostHog from 'posthog-js-lite';

const PosthogContext = createContext(null);

export function PosthogProviderLite({ apiKey, apiHost, options, children }) {
  // Initialize PostHog object only once (or when config changes)
  const posthog = useMemo(() => new PostHog(apiKey, { host: apiHost, ...options }), [apiKey, apiHost, options]);

  return (
    <PosthogContext.Provider value={posthog}>
      {children}
    </PosthogContext.Provider>
  );
}

export function usePosthog() {
  const posthog = useContext(PosthogContext);
  if (!posthog) {
    throw new Error("usePosthog must be used within a PosthogProviderLite");
  }
  return posthog;
}
