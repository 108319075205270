import React, { useMemo, useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { matchPath } from 'react-router';
import { usePosthog } from './PosthogProviderLite';
import { setUserIdInGTM } from '../data/gtm';
import { Api, Storage, tk } from '../utilities';
// import { addFacebookPixel } from '../utilities/fbq';
import { AuthContext } from '../context';
import { useTracking } from '../data/gtm';
import { calculateConversion } from '../data/gtm';
import { useSearchParams } from 'react-router-dom';
import { FRAMI_URL_EXCLUSION } from './FramiProvider';
import { useNavigateSearch } from '../utilities/helpers';
import { logError } from '../utilities/logError';

export function AuthProvider({ isFeatureFlagAvailable, children }) {
    const posthog = usePosthog();
    const [user, setUser] = useState();
    const [temporaryUser, setTemporaryUser] = useState();
    const [userToken, setUserToken] = useState(null);
    const [loading, setLoading] = useState(true);
    const [marketingAccepted, setMarketingAccepted] = useState(false);
    const [galleryInfoAccepted, setGalleryInfoAccepted] = useState(false);
    const [cookies] = useCookies(['ad_storage']);
    const [eventsCounter, setEventsCounter] = useState({
        composition_select: 0,
        photo_upload: 0,
        ready_to_buy: 0,
        add_to_cart: 0,
        payment: 0,
    });


    const getFramiIdFromLocation = () => {
        const match =
            matchPath("/:framiId/:compId/:drawerId", window.location.pathname) ||
            matchPath("/:framiId/:compId", window.location.pathname) ||
            matchPath("/:framiId", window.location.pathname);

        const framiId = match?.params?.framiId
        if (framiId && (framiId.length !== 8 || FRAMI_URL_EXCLUSION.includes(framiId))) {
            return null;
        }
        return framiId;
    }

    const [searchParams] = useSearchParams();

    const { pushEvent } = useTracking();

    const navigate = useNavigateSearch();

    // useEffect(() => {
    //     if (env.PIXEL_ID && cookies.ad_storage === 'granted') {
    //         // addFacebookPixel(env.PIXEL_ID);
    //     }
    // }, [cookies.ad_storage]);

    useEffect(() => {
        const init = async () => {
            let token = searchParams.get('token');

            if (token) {
                tk.setToken(token);
                setUserToken(token);
            } else {
                token = tk.getToken();
            }

            if (token) {
                await login(token);
            } else {
                await loadTemporaryUser();
            }
        };
        // Nie chcemy inicjować AuthProvidera, jeżeli aktualnie logujemy użytkownika
        if (!window.location.pathname.startsWith('/auth')) {
            init();
        }
        setLoading(false);
    }, []);

    const reloadUser = async () => {
        try {
            const user = await Api.get('/dj-rest-auth/user/');
            setEventsCounter(user.google_events_count ?? user.events);
            setMarketingAccepted(user.marketing_accepted);
            setGalleryInfoAccepted(user.gallery_info_accepted);
            // identifyUser(user);

            if (user.email_verified) {
                setUser(user);
            } else if (user.gallery_info_accepted) {
                setTemporaryUser(user);
            }
        } catch (err) {
            logError(err);
        }
    };

    const loadTemporaryUser = async () => {
        tk.removeToken();
        Storage.removeItem('ready_to_buy');
        try {
            const { token } = await Api.post('/auth/create/', { language_code: window.navigator.language.slice(0, 2) });
            tk.setToken(token);
            setUserToken(token);

            const user = await Api.get('/dj-rest-auth/user/');
            setTemporaryUser(user)
            setEventsCounter(user.google_events_count ?? user.events);
            setMarketingAccepted(user.marketing_accepted);
            setGalleryInfoAccepted(user.gallery_info_accepted);
            // identifyUser(user);
        } catch (err) {
            logError(err);
        }
    }

    const logout = async () => {
        await loadTemporaryUser();
        setUser(null);
        navigate('/');
        window.location.reload(false);
    };

    const login = async (token) => {
        tk.setToken(token);
        setUserToken(token);

        try {
            const user = await Api.get('/dj-rest-auth/user/');
            setEventsCounter(user.google_events_count ?? user.events);
            setMarketingAccepted(user.marketing_accepted);
            setGalleryInfoAccepted(user.gallery_info_accepted);
            identifyUser(user);

            const framiId = getFramiIdFromLocation();

            if (framiId && !user.is_staff && !user.frami_set.some((frami) => frami.id === framiId)) {
                navigate(`/${user.last_visited_frami}/`);
            }

            // We are currently serving the google auth code, we need to redirect to the last visited frami
            if (window.location.pathname.startsWith('/auth')) {
                navigate(`/${user.last_visited_frami}/`);
            }

            if (user.ready_to_buy && !Storage.getItem('ready_to_buy')) {
                Storage.setItem('ready_to_buy', true);
            }

            if (user.email_verified) {
                setUser(user);
                return user;
            }
            else {
                setTemporaryUser(user);
                return user;
            }
        } catch (err) {
            logError(err);
        }
    };


    useEffect(() => {
        if (user) {
            const updatedEventsCount = (eventsCounter['login'] || 0) + 1;
            setEventsCounter((prev) => ({ ...prev, login: updatedEventsCount }));
            updateGoogleEventsCounter({ ...eventsCounter, login: updatedEventsCount });
            const percentValue = calculateConversion('login', updatedEventsCount);
        
            pushEvent('identify', {
                currency: "PLN",
                value: percentValue * 800,
            }, null, user);
        }
    }, [user?.email]);


    const identifyUser = (user) => {
        const urlParams = new URLSearchParams(window.location.search);
        const phVisitor = urlParams.get('ph_visitor');

        if (user && user.pk) {
            posthog?.identify(user.pk, {
                email: user.email,
                language: window.navigator.language.slice(0, 2),
                created_at: user.created_at,
                user_country: user.country,
            });
            if (user.email_verified) {
                setUserIdInGTM(user.pk);
            }
            console.log('Posthog identify - ', user.pk);
            // if (phVisitor) {
            //     posthog?.alias(user.pk, phVisitor);
            //     console.log('Posthog alias - ', user.pk, phVisitor);
            // } else {
            //     console.warn('Posthog alias - ph_visitor is undefined');
            // }
        } else {
            if (!user) {
                console.warn('Posthog identify - User is undefined');
            } else if (!user.pk) {
                console.warn('Posthog identify - User.pk is undefined');
            }
        }

        if (!posthog) {
            console.warn('Posthog is undefined');
        }
    };

    const updateGoogleEventsCounter = async (eventsCount) => {
        try {
            const user = await Api.patch('/dj-rest-auth/user/', { events: eventsCount });
            if (user.email_verified) {
                setUser(user);
            }
        } catch (err) {
            logError(err);
        }
    };

    const memoizedContext = useMemo(
        () => ({
            user,
            temporaryUser,
            eventsCounter,
            marketingAccepted,
            galleryInfoAccepted,
            setEventsCounter,
            isFeatureFlagAvailable,
            login,
            logout,
            reloadUser,
            updateGoogleEventsCounter,
            userToken
        }),
        [isFeatureFlagAvailable, user, eventsCounter]
    );

    return <AuthContext.Provider value={memoizedContext}>{!loading && children}</AuthContext.Provider>;
}
