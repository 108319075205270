class Storage {
    getItem(name) {
        return localStorage.getItem(name);
    }

    setItem(name, item) {
        return localStorage.setItem(name, item);
    }

    setItems(obj) {
        return Object.entries(obj).forEach(([key, value]) => {
            localStorage.setItem(key, value);
        });
    }

    removeItem(name) {
        return localStorage.removeItem(name);
    }
}

export default new Storage();
