import React, { useReducer, useState, useEffect, useMemo, useCallback } from 'react';
import { matchPath } from 'react-router';
import { useLocation, useSearchParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';

import { useAuth, FramiContext } from '../context';
import Loader, { LoaderWrapper } from '../components/common/Loader';
import { SIZES } from '../components/studio/CompositionSize';
import { useTracking } from '../data/gtm';
import { Api } from '../utilities';
import { addOneDayToDate, getQueryParams, useNavigateSearch } from '../utilities/helpers';
import { uiColors } from '../common/styles';
import { hexToLab, labToHex } from '../utilities/colorConversion';
import { logError } from '../utilities/logError';

export const FRAMI_URL_EXCLUSION = ['batch_photo_editor', 'checkout'];


const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_NEW':
            return action.payload;
        case 'UPDATE_FRAMI':
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};


export function FramiProvider(props) {
    const [currentFrami, dispatch] = useReducer(reducer, null);
    const [openLoginModal, setOpenLoginModal] = useState(false);
    const [materials, setMaterials] = useState([]);
    const [categories, setCategories] = useState([]);
    const [frameSizes, setFrameSizes] = useState([]);
    const [papers, setPapers] = useState({});
    const [cardboardPassepartouts, setCardboardPassepartouts] = useState([]);
    const [predefinedFrameStyles, setPredefinedFrameStyles] = useState([]);
    const [imageFilters, setImageFilters] = useState([]);

    const [isFramiObsolete, setIsFramiObsolete] = useState(false);
    const obsoleteFrameMaterialIds = ['WH', 'CMK', 'DIGITAL', 'NF'];
    const { userToken } = useAuth();
    const { pushEvent } = useTracking();
    const navigate = useNavigateSearch();

    const { pathname } = useLocation()

    const match = useMemo(() => {
        return (
            matchPath("/:framiId/:compId/:drawerId", pathname) ||
            matchPath("/:framiId/:compId", pathname) ||
            matchPath("/:framiId", pathname)
        )
    }, [pathname]);

    const framiId = useMemo(() => {
        const framiId = match?.params?.framiId
        if (framiId && (framiId.length !== 8 || FRAMI_URL_EXCLUSION.includes(framiId))) {
            return null;
        }
        return framiId;
    }, [match]);

    const [searchParams, setSearchParams] = useSearchParams();
    const { width, height, size } = getQueryParams(searchParams);

    useEffect(() => {
        if (userToken) {
            fetchFrami(framiId);
        }
    }, [userToken]);

    useEffect(() => {
        if ((currentFrami && size) || (currentFrami && (width || height))) {
            const w = width ? width : size ? SIZES[size].w : currentFrami.wall_shape[1][0];
            const h = height ? height : size ? SIZES[size].h : currentFrami.wall_shape[2][1];
            const wallShape = [
                [0, 0],
                [w, 0],
                [w, h],
                [0, h],
                [0, 0],
            ];
            updateFramiWallShape(wallShape);
            searchParams.delete('size');
            searchParams.delete('width');
            searchParams.delete('height');
            setSearchParams(searchParams);
        }
    }, [currentFrami, size, width, height]);

    useEffect(() => {
        if (currentFrami) {
            setIsFramiObsolete(!['15x16_1', 'TYPE1'].includes(currentFrami.frame_series));
        }
    }, [currentFrami?.frame_series]);

    useEffect(() => {
        // console.log('useEffect currentFrami', currentFrami);
        if (
            (currentFrami?.coupon?.redeem_by && new Date() > addOneDayToDate(new Date(currentFrami.coupon?.redeem_by)))
        ) {
            removeCoupon();
        }
    }, [currentFrami]);

    useEffect(() => {
        fetchLUTFilters();
        fetchCategories();
        fetchPredefinedFrameStyles();
    }, []);

    useEffect(() => {
        fetchMaterials();
        fetchPapers();
        fetchCardboardPassepartouts();
    }, [userToken]);

    useEffect(() => {
        if (currentFrami && currentFrami.frame_series !== '15x16_1') {
            fetchFrameSizes();
        }
    }, [currentFrami?.frame_series]);

    const setCurrentFrami = (frami) => {
        const contrastColor = calculateContrastColor(frami.wall_color, uiColors.SECONDARY_TEXT, 0.45);
        const payload = { ...frami, contrast_color: contrastColor }
        dispatch({ type: 'SET_NEW', payload: payload });
    };

    const updateFramiMaterial = (material) => {
        dispatch({ type: 'UPDATE_FRAMI', payload: { default_frame_material: material.id } });
        updateFrami({ default_frame_material: material.id });
    };

    const updateFramiHangingSystem = (hangingSystem) => {
        dispatch({ type: 'UPDATE_FRAMI', payload: { default_hanging_system: hangingSystem } });
        updateFrami({ default_hanging_system: hangingSystem });
    };

    const updateFramiPaper = (paper) => {
        dispatch({ type: 'UPDATE_FRAMI', payload: { default_paper: paper } });
        updateFrami({ default_paper: paper });
    };

    const updateFramiCardboardPassepartout = (passepartoutId, passepartoutWidth) => {
        const payload = {
            default_cardboard_passepartout: passepartoutId,
        };
        if (passepartoutWidth != null) {
            payload['default_passepartout_width'] = passepartoutWidth;
        }
        dispatch({ type: 'UPDATE_FRAMI', payload: payload });
        updateFrami(payload);
    };

    const updateFramiLUTFilter = (filterId) => {
        dispatch({ type: 'UPDATE_FRAMI', payload: { default_lut_filter: filterId } });
        updateFrami({ default_lut_filter: filterId });
    };

    const updateFramiPredfinedFrameStyle = (frameStyle) => {
        const payload = {
            default_predefined_frame_style: frameStyle?.id || null,
        };
        if (frameStyle) {
            if (frameStyle.passepartout_width) {
                payload['default_passepartout_width'] = frameStyle.passepartout_width;
            }
            if (frameStyle.frame_materials.length === 1) {
                payload['default_frame_material'] = frameStyle.frame_materials[0];
            }
            const lutFilter = frameStyle?.lut_filter || 'NONE';
            if (lutFilter) {
                payload['default_lut_filter'] = lutFilter;
            }
        }
        dispatch({ type: 'UPDATE_FRAMI', payload: payload });
        updateFrami(payload);
    };

    const updateFramiWallShape = (value) => {
        dispatch({ type: 'UPDATE_FRAMI', payload: { wall_shape: value } });
        updateFrami({ wall_shape: value });
    };

    const updateFramiStairsWallShape = (value) => {
        dispatch({ type: 'UPDATE_FRAMI', payload: { stairs_wall_shape: value } });
        updateFrami({ stairs_wall_shape: value });
    };

    const calculateContrastColor = (wallColorHex, baseColorHex, minLuminanceDifference = 0.5) => {
        if (!wallColorHex) {
            return baseColorHex;
        }
        // Convert HEX to Lab using imported functions
        const baseColorLab = hexToLab(baseColorHex);
        const wallColorLab = hexToLab(wallColorHex);
    
        const [baseL, baseA, baseB] = baseColorLab;
        const [wallL] = wallColorLab;
    
        let newL;
        if (wallL > 50) {
            newL = wallL - minLuminanceDifference * 100;
        } else {
            newL = wallL + minLuminanceDifference * 100;
        }
        newL = Math.max(0, Math.min(100, newL));
    
        const contrastColorLab = [newL, baseA, baseB];
        const contrastColorHex = labToHex(contrastColorLab);
    
        console.log('contrastColorHex', wallColorHex, contrastColorHex);
        return contrastColorHex;
    };

    const updateFramiWallColor = (value) => {
        const contrastColor = calculateContrastColor(value, uiColors.SECONDARY_TEXT, 0.45);
        dispatch({ type: 'UPDATE_FRAMI', payload: { wall_color: value, contrast_color:contrastColor } });
        pushEvent('wallcolor_set_color', { color: value });
        updateFrami({ wall_color: value });
    };

    const updateFrami = async (obj) => {
        await Api.put(`/composer/frami/${currentFrami.id}/`, { ...obj });
    };

    const getFramis = async (framiId) => {
        let URL;

        if (framiId) {
            URL = `/composer/frami/${framiId}/`;
        } else {
            URL = '/composer/frami/';
        }

        return await Api.get(URL);
    };

    const fetchFrami = async (framiId) => {

        let framis;

        try {
            if (!framiId) {
                framis = await getFramis();
            } else {
                framis = [await getFramis(framiId)];
            }
            setCurrentFrami(framis[0]);
            if (framis[0].frame_sizes) {
                setFrameSizes(framis[0].frame_sizes);
            }
        } catch (err) {
            if (err.response) {
                setOpenLoginModal(true);
            }
        }
    };

    const createFrami = async () => {
        try {
            const frami = await Api.post('/composer/frami/');
            navigate(`/${frami.id}`);
            window.location.reload(true);
        } catch (err) {
            logError(err);
        }
    };

    const fetchMaterials = async () => {
        try {
            let data = await Api.get('/composer/frame_materials/');
            if (Array.isArray(data)) {
                data.sort((a, b) => {
                    if (a.is_bestseller === b.is_bestseller) {
                        return a.price_per_m - b.price_per_m;
                    }
                    return a.is_bestseller ? -1 : 1;
                });
                setMaterials(data);
            } else {
                setTimeout(fetchMaterials, 2000);
            }
        } catch (error) {
            console.error(error);
            setTimeout(fetchMaterials, 2000);
        }
    };

    const fetchCategories = async () => {
        try {
            const categories = await Api.get('/composer/category/');
            setCategories(categories);
        } catch (err) {
            logError(err);
        }
    };

    const fetchFrameSizes = async () => {
        try {
            const frames = await Api.get('/composer/frame_sizes/');
            setFrameSizes((prev) => [...prev, ...frames]);
        } catch (err) {
            logError(err);
        }
    };

    const fetchPapers = async () => {
        try {
            const papers = await Api.get('/composer/papers/');
            papers.sort((a, b) => a.price_per_m2 - b.price_per_m2);
            setPapers(papers);
        } catch (err) {
            logError(err);
        }
    };

    const fetchCardboardPassepartouts = async () => {
        try {
            const cardboardPassepartouts = await Api.get('/composer/cardboard_passepartouts/');
            setCardboardPassepartouts(cardboardPassepartouts);
        } catch (err) {
            logError(err);
        }
    };

    const fetchPredefinedFrameStyles = async () => {
        try {
            const predefinedFrameStyles = await Api.get('/composer/predefined_frame_styles/');
            predefinedFrameStyles.sort((a, b) => {
                if (a.is_bestseller === b.is_bestseller) {
                    return a.frame_materials.length - b.frame_materials.length;
                }
                return a.is_bestseller ? -1 : 1;
            });
            setPredefinedFrameStyles(predefinedFrameStyles);
        } catch (err) {
            logError(err);
        }
    };

    const fetchLUTFilters = async () => {
        try {
            const filters = await Api.get('/composer/lut_filters/');
            setImageFilters(filters);
        } catch (err) {
            logError(err);
        }
    };

    const applyCoupon = async (code) => {
        if (!currentFrami) {
            return;
        }
        try {
            const updatedFrami = await Api.patch(`/composer/frami/${currentFrami.id}/apply_coupon/`, {
                code: code.toUpperCase(),
            });
            dispatch({ type: 'UPDATE_FRAMI', payload: { coupon: updatedFrami.coupon } });
        } catch (err) {
            console.log(err);
        }
    };

    const removeCoupon = async () => {
        if (currentFrami.coupon?.code) {
            try {
                const updatedFrami = await Api.patch(`/composer/frami/${currentFrami.id}/remove_coupon/`);
                dispatch({ type: 'UPDATE_FRAMI', payload: { coupon: updatedFrami.coupon } });
            } catch (err) {
                console.log(err);
            }
        }
    };

    const memoizedMaterials = useMemo(() => {
        if (Array.isArray(materials)) {
            let filteredMaterials;
            if (isFramiObsolete) {
                filteredMaterials = materials.filter((item) => obsoleteFrameMaterialIds.includes(item.id));
            } else {
                filteredMaterials = materials;
            }

            return new Map(filteredMaterials.map((item) => [item.id, item]));
        }
        return new Map();
    }, [materials, isFramiObsolete]);

    const memoizedCategories = useMemo(() => {
        if (Array.isArray(categories)) {
            return new Map(categories.map((item) => [item.id, item]));
        }
        return new Map();
    }, [categories]);

    const memoizedFrameSizes = useMemo(() => {
        if (Array.isArray(frameSizes)) {
            return new Map(frameSizes.map((item) => [item.id, item]));
        }
        return new Map();
    }, [frameSizes]);

    const memoizedHorizontalFrameSizes = useMemo(() => {
        if (Array.isArray(frameSizes)) {
            const sortedSizes = sortBy(frameSizes, ['height', 'width']);
            return new Map(
                sortedSizes
                    .filter(
                        (item) =>
                            item.frame_series === currentFrami.frame_series &&
                            item.width > item.height &&
                            item.is_standard &&
                            item.available
                    )
                    .map((item) => [item.id, item])
            );
        }
        return new Map();
    }, [frameSizes, currentFrami?.frame_series]);

    const memoizedVerticalFrameSizes = useMemo(() => {
        if (Array.isArray(frameSizes)) {
            const sortedSizes = sortBy(frameSizes, ['width', 'height']);
            return new Map(
                sortedSizes
                    .filter(
                        (item) =>
                            item.frame_series === currentFrami.frame_series &&
                            item.width < item.height &&
                            item.is_standard &&
                            item.available
                    )
                    .map((item) => [item.id, item])
            );
        }
        return new Map();
    }, [frameSizes, currentFrami?.frame_series]);

    const memoizedSquareFrameSizes = useMemo(() => {
        if (Array.isArray(frameSizes)) {
            const sortedSizes = sortBy(frameSizes, ['width']);
            return new Map(
                sortedSizes
                    .filter(
                        (item) =>
                            item.frame_series === currentFrami.frame_series &&
                            item.width === item.height &&
                            item.is_standard &&
                            item.available
                    )
                    .map((item) => [item.id, item])
            );
        }
        return new Map();
    }, [frameSizes, currentFrami?.frame_series]);

    const memoizedPapers = useMemo(() => {
        if (Array.isArray(papers)) {
            return new Map(papers.map((item) => [item.id, item]));
        }
        return new Map();
    }, [papers]);

    const memoizedCardboardPassepartouts = useMemo(() => {
        if (Array.isArray(cardboardPassepartouts)) {
            return new Map(
                cardboardPassepartouts.map((item) => [
                    item.id,
                    {
                        id: item.id,
                        color: item.color_hex,
                        thickness: item.thickness_mm,
                        price_per_m2: item.price_per_m2,
                        available: item.available,
                    },
                ])
            );
        }
        return new Map();
    }, [cardboardPassepartouts]);

    const memoizedPredefinedFrameStyles = useMemo(() => {
        if (Array.isArray(predefinedFrameStyles)) {
            return new Map(predefinedFrameStyles.map((item) => [item.id, item]));
        }
        return new Map();
    }, [predefinedFrameStyles]);

    const memoizedImageFilters = useMemo(() => {
        if (Array.isArray(imageFilters)) {
            return new Map(imageFilters.map((item) => [item.id, item]));
        }
        return new Map();
    }, [imageFilters]);

    const getFrameSizeAndMaterial = useCallback(
        (frame) => {
            const frameSize = memoizedFrameSizes.get(frame.frame_size);
            const frameMaterial = memoizedMaterials.get(frame.frame_material);

            return { frameSize, frameMaterial };
        },
        [memoizedFrameSizes, memoizedMaterials]
    );

    const getMinFrameSizeWidth = useCallback(() => {
        if (Array.isArray(frameSizes)) {
            return frameSizes.reduce((min, frameSize) => (min.width < frameSize.width ? min : frameSize)).width;
        }
    }, [frameSizes]);

    const getMinFrameSizeHeight = useCallback(() => {
        if (Array.isArray(frameSizes)) {
            return frameSizes.reduce((min, frameSize) => (min.height < frameSize.height ? min : frameSize)).height;
        }
    }, [frameSizes]);

    const getMaxFrameSizeWidth = useCallback(() => {
        if (Array.isArray(frameSizes)) {
            return frameSizes.reduce((max, frameSize) => (max.width > frameSize.width ? max : frameSize)).width;
        }
    }, [frameSizes]);

    const getMaxFrameSizeHeight = useCallback(() => {
        if (Array.isArray(frameSizes)) {
            return frameSizes.reduce((max, frameSize) => (max.height > frameSize.height ? max : frameSize)).height;
        }
    }, [frameSizes]);

    const value = {
        currentFrami,
        isFramiObsolete,
        memoizedMaterials,
        memoizedCategories,
        memoizedFrameSizes,
        memoizedHorizontalFrameSizes,
        memoizedVerticalFrameSizes,
        memoizedSquareFrameSizes,
        memoizedPapers,
        memoizedCardboardPassepartouts,
        memoizedPredefinedFrameStyles,
        memoizedImageFilters,
        obsoleteFrameMaterialIds,
        openLoginModal,
        setOpenLoginModal,
        applyCoupon,
        createFrami,
        removeCoupon,
        setCurrentFrami,
        updateFramiMaterial,
        updateFramiCardboardPassepartout,
        updateFramiLUTFilter,
        updateFramiWallColor,
        updateFramiWallShape,
        updateFramiStairsWallShape,
        updateFramiHangingSystem,
        updateFramiPaper,
        updateFramiPredfinedFrameStyle,
        fetchFrami,
        getFrameSizeAndMaterial,
        getMinFrameSizeWidth,
        getMinFrameSizeHeight,
        getMaxFrameSizeWidth,
        getMaxFrameSizeHeight,
    };

    if (isEmpty(materials)) {
        return (
            <LoaderWrapper $overlap>
                <Loader />
            </LoaderWrapper>
        );
    }

    return !isEmpty(materials) && <FramiContext.Provider value={value} {...props} />;
}
