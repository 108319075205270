import env from 'react-dotenv';
import axios from 'axios';
import tk from './TokenKeeper';

class Api {
    getToken() {
        return tk.getToken();
    }

    getAuthHeader() {
        const token = this.getToken();
        if (token) {
            return { Authorization: `Token ${token}` };
        }
    }

    getCancelToken(cancelToken) {
        if (cancelToken) {
            return { cancelToken: cancelToken };
        }
    }

    async get(path, cancelToken, options = {}) {
        const { data } = await axios.get(env.API_URL + path, {
            headers: {
                ...this.getAuthHeader(),
            },
            ...this.getCancelToken(cancelToken),
            ...options,
        });
        return data;
    }

    async put(path, payload) {
        const { data } = await axios.put(env.API_URL + path, payload, {
            headers: {
                ...this.getAuthHeader(),
            },
        });
        return data;
    }

    async patch(path, payload, options = {}) {
        const retries = 3;
        const delay = 1000;
        for (let i = 0; i < retries; i++) {
            try {
                const { data } = await axios.patch(env.API_URL + path, payload, {
                    headers: {
                        ...this.getAuthHeader(),
                    },
                    ...options,
                });
                return data;
            } catch (error) {
                if (error.response && error.response.status === 500 && error.response.data.includes('database is locked')) {
                    if (i < retries - 1) {
                        await new Promise(resolve => setTimeout(resolve, delay));
                    } else {
                        throw new Error('Database is locked after several retries');
                    }
                } else {
                    throw error;
                }
            }
        }
    }

    async post(path, payload, config) {
        const { data } = await axios.post(env.API_URL + path, payload, {
            headers: {
                ...this.getAuthHeader(),
            },
            ...config,
        });
        return data;
    }

    async delete(path) {
        const { data } = await axios.delete(env.API_URL + path, {
            headers: {
                ...this.getAuthHeader(),
            },
        });
        return data;
    }
}

export default new Api();
